<template>
  <b-card-actions ref="card" title="" action-refresh @refresh="refresh">

    <b-tabs v-model="tabIndex">
      <b-tab>
        <template #title>
          <feather-icon icon="HomeIcon"/>
          <span>  {{ $t('Legal Regulation') }}
</span>
        </template>

        <div>
          <!-- User Info: Input Fields -->
          <b-form>
            <b-row>

              <!-- Field: AREA -->
              <b-col
                  cols="7"
                  md="7"
              >
                <b-form-group
                    :label="$t('Area')"
                    label-for="area"
                >

                  <vue-autosuggest
                      :disabled="allEditDisabled"
                      :suggestions="filteredOptions"
                      :limit="10"
                      :input-props="{id:'autosuggest__input',class:'form-control'}"
                      id="area"
                      @input="onInputChange"
                      @selected="onSuggestionSelection"
                      v-model="legalRegulation.area"
                  >
                    <template slot-scope="{suggestion}">
                      <span class="my-suggestion-item">{{ suggestion.item }}</span>
                    </template>
                  </vue-autosuggest>


                </b-form-group>
              </b-col>

              <!-- Year -->
              <b-col
                  cols="3"
                  md="3"
              >
                <b-form-group
                    :label="$t('Year')"
                    label-for="year"
                >
                  <b-form-input
                      :disabled="allEditDisabled"
                      id="model"
                      v-model="legalRegulation.year"
                  />
                </b-form-group>
              </b-col>

              <!-- archived -->
              <b-col
                  cols="2"
                  md="2"
              >
                <b-form-group
                    :label="$t('Status')"
                    label-for="archived"
                >
                  <b-form-checkbox
                      v-model="archived"
                      name="terminated"
                      id="terminated"
                      switch
                      inline
                      class="mt-06 custom-control-danger"
                      :disabled="allEditDisabled"

                  >
                    {{ archived_label }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- Field: LegalRegulation ID -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Title')"

                    label-for="title"
                >
                  <b-form-input
                      id="title"
                      :disabled="allEditDisabled"
                      v-model="legalRegulation.title"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Field: Field -->
            <b-row>
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Field')"
                    label-for="field"
                >
                  <b-form-textarea
                      rows="2"
                      id="note"
                      name="field"
                      v-model="legalRegulation.field"
                      :disabled="allEditDisabled"
                  />
                </b-form-group>
              </b-col>


            </b-row>

            <b-row v-if="false">
              <!-- Field: Link -->
              <b-col
                  cols="12"
                  md="12"
              >
                <b-form-group
                    :label="$t('Link')"
                    label-for="link"
                >
                  <b-form-textarea
                      rows="2"
                      id="note"
                      name="link"
                      v-model="legalRegulation.link"
                      :disabled="allEditDisabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>


          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="handleSubmit()"
              :disabled="allEditDisabled"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click.prevent.stop="back($event)"
          >
            {{ $t('Cancel') }}


          </b-button>
        </div>


      </b-tab>


    </b-tabs>
  </b-card-actions>


</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BCardText,
  BButton,
  BCard,
  BMedia,
  BAvatar,
  BFormTextarea,
  BTable,
  BTabs,
  BTab,
  BCardHeader,
  BDropdown,
  BDropdownItem,

  BCardTitle, BFormFile,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import Ripple from 'vue-ripple-directive'
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import EquipmentService from "@/basic/equipmentService";
import LegalService from "@/basic/legalService";
import useUsersList from "@/views/apps/user/users-list/useUsersList";
import {ref} from "@vue/composition-api";
import {avatarText} from "@core/utils/filter";
import vSelect from 'vue-select'
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ability from "@/libs/acl/ability";
import {VueAutosuggest} from 'vue-autosuggest'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BAvatar,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    BMedia,
    BTabs,
    BDropdown,
    BTab,
    BDropdownItem,
    flatPickr,
    Spacer,
    vSelect,
    BFormFile,
    Cropper,
    VueAutosuggest,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      legalRegulation: {
        area: "",
        field: "",
        title: "",
        link: "",
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      editDisabled: false,
      allEditDisabled: !ability.can("manage", "Basic Admin"),
      department_new_id: 1,
      role_new_id: 1,
      legalRegulation_roles: [{id: 1, name: ""}],
      legalRegulation_departments: [{id: 1, name: ""}],
      dateDefault: null,
      assignment_options: ['a', 'b', 'c'],
      assignment_selected: [],
      protected_category: false,
      documents_enabled: false,
      medical_enabled: false,
      avatar_file: null,
      filedata: null,
      images: null,
      url: "",
      variant: "light",
      opacity: 0.85,
      blur: "2px",
      crop: {
        src: "",
        type: "image/jpg",
      },
      file_name: "",
      showCropper: false,
      tab: this.$route.params.tab ? this.$route.params.tab : "main",
      tabIndex: 1,
      suggestions: [],
      filteredOptions: [],
      areas: [],
      limit: 10,
      selected: null,
      archived: false,
      from_company: false,
      from_company_id: 0,
    }
  },
  computed: {
    currentEmployee() {
      if (this.employees && this.new_employee_id) {
        let employee = this.employees.find(l => l.id == this.new_employee_id);
        console.log("currentEmployee section", employee)
        return employee
        //return {id: "1", name: "alfa"}
      }
      return {id: "-11", first_name: "Non Assegnato", last_name: ""}
    },
    archived_label() {
      return (this.archived) ? i18n.t('Archived') : i18n.t('Not archived');
    },
  },
  methods: {

    onSuggestionSelection(selectedItem) {
      console.log('selectedItem', selectedItem)
      this.legalRegulation.area = selectedItem.item
    },
    onInputChange(text) {

      console.log("onInputChange Text", text)
      console.log("onInputChange filteredOptions", this.filteredOptions)
      if (text === '' || text === undefined) {
        this.filteredOptions = [{
          data: this.areas,
        }]
      }

      const filteredData = this.areas.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },

    refresh() {


      const legalService = new LegalService(useJwt)

      let legalRegulation_id = this.$route.params.id;
      this.from_company_id = this.$route.params.from_company;
      if (this.from_company_id) {
        this.from_company = true;
      }
      this.$refs["card"].showLoading = true


      legalService.legal_regulation(legalRegulation_id).then(response => {


        if (response.data.data.legal_regulation) {
          console.log("legalRegulation", response.data.data.legal_regulation)
          this.documents_enabled = true;
          this.legalRegulation = response.data.data.legal_regulation;

          if (this.legalRegulation.archived_at) {
            this.archived = true;
          }
        } else {
          console.log('legalRegulation not found')
        }

        if (response.data.data.areas) {
          this.areas = response.data.data.areas;
          this.filteredOptions = [{data: this.areas,}];

        } else {
          console.log('areas not found')
        }

        console.log('tabIndex s', this.tabIndex)

        console.log('data', response.data.data)
        this.$refs["card"].showLoading = false
        console.log("refresh filteredOptions", this.filteredOptions)

      })


    },
    async back(e) {
      if (this.from_company){
        this.$router.push({name: 'company-legal-regulations'})
      }
      else{
        this.$router.push({name: 'legal-regulations'})

      }

    },
    setEmployee(employee) {
      this.new_employee_id = employee.id;
      this.legalRegulation.employee_id = this.new_employee_id
    },
    selectedFile(event) {
      this.success = "";
      this.error = "";
      var filePath = event.target.files[0].name;
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if (!allowedExtensions.exec(filePath)) {
        this.error = this.$t("FileUploadError");
        this.$toasted.show(this.error, {
          className: "top-alert",
          position: "top-center",
          type: "error",
          duration: 2000,
        });
        $("#profile_image").val("");
      } else {
        this.images = event.target.files[0];
        this.file_name = this.images.name;
        this.url = URL.createObjectURL(this.images);
        this.crop.src = URL.createObjectURL(this.images);
        this.showCropper = true;
      }
    },

    handleUpload() {

      let filevalue = this.avatar_file;
      console.log('handleUpload filevalue', filevalue)

      var filePath = filevalue.name;
      console.log('handleUpload filePath', filevalue.name)


      let reader = new FileReader();

      reader.onload = (e) => {
        console.log('readAsDataURL', e.target.result);
        this.filedata = e.target.result
        const data_upload = {
          filedata: this.filedata,
          filename: filePath
        };
        console.log("UPLOAD 2", filedata)

        this.legalRegulation.photo = filedata;

      };

      reader.readAsDataURL(this.avatar_file);


    },

    cropImage() {
      const result = this.$refs.cropper.getResult();
      let obj = this;
      this.url = result.canvas.toDataURL(this.crop.type);
      console.log('url', this.url)
      this.urltoFile(
          result.canvas.toDataURL(this.crop.type),
          this.file_name,
          "image/*"
      ).then(function (file) {
        console.log("rahul--", file);
        obj.images = file;

      });
      this.images = obj.images;
      this.showCropper = false;

    },

    deleteImage() {
      console.log('deleteImage')
      this.images = "";
      this.showCropper = false;
      this.url = require("@/assets/images/avatars/avatar.png");


    },

    urltoFile(url, filename, mimeType) {
      return fetch(url)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], filename, {type: mimeType});
          });
    },


    async handleSubmit(e) {
      const legalService = new LegalService(useJwt)
      this.submitted = true;
      //const valid = await this.$validator.validate();
      const valid = true;

      if (!valid) {
        this.$vs.notify({
          title: this.$t("create_admin.FailedToCreateAdmin"),
          text: this.$t("create_admin.ConfirmPasswordFailed"),
          color: "danger",
          position: "top-center"
        });
        this.submitted = false;
        return false;
      }

      this.$refs["card"].showLoading = true
      const data = {
        legal_regulation: this.legalRegulation,
        archived: this.archived,
        from_company_id: this.from_company_id,
      };


      try {
        console.log('data', data)
        let legalRegulation_id = this.$route.params.id;

        legalService.update_legal_regulation(legalRegulation_id, data).then(response => {
          console.log("response", response)
          this.$refs["card"].showLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: i18n.t('Legal Regulation'),
              icon: 'AlertIcon',
              variant: 'success',
              text: i18n.t('Legal Regulation saved with success'),
            },
          })
          if (this.from_company){
            this.$router.push({name: 'company-legal-regulations'})
          }
          else{
            this.$router.push({name: 'legal-regulations'})

          }
        })

        const response = {status: 200, data: {id: 1}}


      } catch (error) {

      }
      this.submitted = false;
    },

  },
  mounted() {
    this.refresh()
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.avatar = base64
      console.log("avatar", props.avatar)
    })

    return {
      resolveUserRoleVariant,
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
